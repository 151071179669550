import { Create, useForm } from '@refinedev/antd';
import { Form, Input, TreeSelect } from 'antd';
import { IRole } from 'interfaces/roles';
import { treePermissions } from 'services/permissions';

export const RoleCreate = () => {
  const { formProps, saveButtonProps } = useForm<IRole>();

  return (
    <Create saveButtonProps={saveButtonProps} title="Criar regra de acesso">
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Permissões" name="permissions">
          <TreeSelect
            treeData={treePermissions()}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_PARENT}
          />
        </Form.Item>
      </Form>
    </Create>
  );
};
