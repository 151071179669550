import qs from 'qs';
import { AxiosResponse } from 'axios';
import { getAPI } from 'requests/api';
import type {
  EvaluationKind,
  IClassroomEnrollment,
  IClassroomEvaluation
} from 'interfaces/classrooms';
import type {
  IAcademicRecord,
  IAcademicRecordsStatus
} from 'interfaces/academic_records';
import type { IAssessment, IAssessmentAttempt } from 'interfaces/assessments';
import type { IAssignmentAttempt } from 'interfaces/assignments';

export interface IClassroomsEnrollmentsData extends IClassroomEnrollment {
  academic_record_score?: IAcademicRecord['score'];
  academic_record_status?: IAcademicRecord['status'];
}

export const EVALUATION_KIND_MAP = {
  objective: 'Objetiva',
  discursive: 'Discursiva',
  assignment: 'Atividade'
};

export const fetchClassroomsEnrollments = async (
  courseEnrollmentId: string
): Promise<IClassroomsEnrollmentsData[]> => {
  const [classroomsEnrollments] = await Promise.all([
    getAPI().get(
      `/admin/classrooms/enrollments?items=100&page=1&filter[by_course_enrollment_id]=${courseEnrollmentId}`
    )
  ]);

  return mountClassroomsEnrollmentsData(classroomsEnrollments.data);
};

export const mountClassroomsEnrollmentsData = (
  classroomsEnrollments: IClassroomEnrollment[]
): IClassroomsEnrollmentsData[] => {
  return classroomsEnrollments.map(classroomEnrollment => {
    let academic_record_status: IAcademicRecordsStatus = 'progress';
    let academic_record_score: number | undefined;

    if (
      classroomEnrollment.status === 'cancelled' ||
      classroomEnrollment.status === 'inactive'
    ) {
      academic_record_status = classroomEnrollment.status;
    } else if (
      classroomEnrollment.status === 'active' &&
      classroomEnrollment.approved !== null
    ) {
      const failedByFrequency =
        classroomEnrollment.attendance_rate <
        classroomEnrollment.classroom.minimum_attendance;
      academic_record_status =
        classroomEnrollment.approved === true
          ? 'approved'
          : `${failedByFrequency ? 'attendance_failed' : 'failed'}`;
      academic_record_score = classroomEnrollment.score;
    }

    return {
      ...classroomEnrollment,
      academic_record_score,
      academic_record_status
    };
  });
};

export interface IEvaluationsWithAttemptsData extends IClassroomEvaluation {
  score: number;
  attempts: (IAssessmentAttempt | IAssignmentAttempt)[];
  evaluationKind: EvaluationKind;
}

export const fetchEvaluations = async (
  classroomId: string,
  classroomEnrollmentId: string
): Promise<IEvaluationsWithAttemptsData[]> => {
  const evaluations = await getAPI().get(
    `/admin/classrooms/evaluations?filter[by_classroom_id]=${classroomId}`
  );

  if (evaluations?.data?.length > 0) {
    const { assessmentAttempts, assignmentAttempts } = await fetchAttempts(
      evaluations.data,
      classroomEnrollmentId
    );

    return mountEvaluationsData(
      evaluations.data,
      assessmentAttempts,
      assignmentAttempts
    );
  }

  return [];
};

export const fetchAttempts = async (
  evaluations: IClassroomEvaluation[],
  classroomEnrollmentId: string
) => {
  const { assessmentIds, assignmentIds } =
    getEvaluationsIdsByAttemptType(evaluations);

  const fetchPromises = [];

  if (assessmentIds.length > 0) {
    fetchPromises.push(
      fetchAssessmentsAttempts(assessmentIds, classroomEnrollmentId)
    );
  }

  if (assignmentIds.length > 0) {
    fetchPromises.push(
      fetchAssignmentsAttempts(assignmentIds, classroomEnrollmentId)
    );
  }

  const [assessmentAttempts, assignmentAttempts] = await Promise.all(
    fetchPromises
  );

  return {
    assessmentAttempts: assessmentAttempts?.data || [],
    assignmentAttempts: assignmentAttempts?.data || []
  };
};

const fetchAssessmentsAttempts = async (
  assessmentIds: string[],
  classroomEnrollmentId: string
) => {
  const assessmentUrl = `/admin/assessments/attempts?${qs.stringify(
    {
      filter: {
        by_evaluations_ids: assessmentIds,
        by_enrollment_id: classroomEnrollmentId
      }
    },
    {
      arrayFormat: 'brackets'
    }
  )}`;

  return await getAPI().get(assessmentUrl);
};

const fetchAssignmentsAttempts = async (
  assignmentIds: string[],
  classroomEnrollmentId: string
) => {
  const assignmentUrl = `/admin/assignment_attempts?${qs.stringify(
    {
      filter: {
        by_evaluations_ids: assignmentIds,
        by_enrollment_id: classroomEnrollmentId
      }
    },
    {
      arrayFormat: 'brackets'
    }
  )}`;

  return await getAPI().get(assignmentUrl);
};

const getEvaluationsKind = async (
  evaluation: IClassroomEvaluation
): Promise<EvaluationKind> => {
  if (evaluation.evaluation_type === 'Assessment') {
    const assessment: AxiosResponse<IAssessment> = await getAPI().get(
      `admin/assessments/${evaluation.evaluation_id}`
    );

    return assessment.data.kind;
  }

  return 'assignment';
};

export const mountEvaluationsData = (
  evaluations: IClassroomEvaluation[],
  assessmentAttempts: IAssessmentAttempt[],
  assignmentAttempts: IAssignmentAttempt[]
): Promise<IEvaluationsWithAttemptsData[]> => {
  const evaluationsDataPromises = evaluations.map(async evaluation => {
    const attempts =
      evaluation.evaluation_type === 'Assessment'
        ? assessmentAttempts.filter(
            attempt => attempt.evaluation_id === evaluation.id
          )
        : assignmentAttempts.filter(
            attempt => attempt.evaluation_id === evaluation.id
          );

    const score = getAttemptsHighScore(attempts);

    const evaluationKind =
      evaluation.evaluation_type === 'Assessment'
        ? attempts.length > 0
          ? (attempts[0] as IAssessmentAttempt).assessment.kind
          : await getEvaluationsKind(evaluation)
        : 'assignment';

    return {
      ...evaluation,
      score,
      attempts,
      evaluationKind
    };
  });

  return Promise.all(evaluationsDataPromises);
};

const getEvaluationsIdsByAttemptType = (
  evaluations: IClassroomEvaluation[]
) => {
  const assessmentIds: string[] = [];
  const assignmentIds: string[] = [];

  for (const evaluation of evaluations) {
    if (evaluation.evaluation_type === 'Assessment') {
      assessmentIds.push(evaluation.id);
    } else {
      assignmentIds.push(evaluation.id);
    }
  }

  return {
    assessmentIds,
    assignmentIds
  };
};

const getAttemptsHighScore = (
  attempts: (IAssessmentAttempt | IAssignmentAttempt)[]
) => {
  const scores = attempts.map(score => score.score);
  return Math.max(...scores);
};
