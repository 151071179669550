import React, { useEffect, useState } from 'react';
import { Button, Typography } from 'antd';
import {
  IAudio,
  IDocument,
  ILibraryContent,
  IVideo
} from 'interfaces/libraries';
import { LoadingOutlined } from '@ant-design/icons';
import { Show } from '@refinedev/antd';
import {
  getAudio,
  getDocument,
  getVideo,
  translateKind
} from 'services/libraries/contents';
import { useParams } from 'react-router-dom';
import { useShow } from '@refinedev/core';

const { Title, Text } = Typography;

export const LibraryContentShow = () => {
  const { id, library_id } = useParams();
  const [video, setVideo] = useState<IVideo | null>(null);
  const [audio, setAudio] = useState<IAudio | null>(null);
  const [document, setDocument] = useState<IDocument | null>(null);
  const { queryResult } = useShow<ILibraryContent>({
    resource: `prometheus/v2/libraries/${library_id}/contents`,
    id
  });

  const { data, isLoading } = queryResult;
  const record = data?.data;

  useEffect(() => {
    if (id && record?.kind === 'video') {
      getVideo(id).then(video => setVideo(video));
    }

    if (id && record?.kind === 'audio') {
      getAudio(id).then(audio => setAudio(audio));
    }

    if (id && record?.kind === 'document') {
      getDocument(id).then(document => setDocument(document));
    }
  }, [id, record?.kind]);

  return (
    <Show isLoading={isLoading} title="Visualizar conteúdo">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Tipo</Title>
      <Text>{translateKind(record?.kind)}</Text>
      {record?.kind === 'video' && (
        <>
          <Title level={5}>Video</Title>
          {video?.delivery_option &&
            video?.delivery_option.provider.kind === 'aws_s3' && (
              <video src={video?.delivery_option.delivery_url} controls />
            )}

          {video?.delivery_option &&
            video?.delivery_option.provider.kind === 'cloudflare_stream' && (
              <iframe
                src={video?.delivery_option.delivery_url}
                width="400"
                height="250"
              />
            )}

          {!video?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}

      {record?.kind === 'audio' && (
        <>
          <Title level={5}>Audio</Title>
          {audio?.delivery_option && (
            <audio src={audio?.delivery_option.delivery_url} controls />
          )}
          {!audio?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}

      {record?.kind === 'document' && (
        <>
          <Title level={5}>Documento</Title>
          {document?.delivery_option && (
            <Button
              href={document?.delivery_option.delivery_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Baixar documento
            </Button>
          )}
          {!document?.delivery_option && (
            <Text>
              <LoadingOutlined />
              Em processamento
            </Text>
          )}
        </>
      )}
    </Show>
  );
};
