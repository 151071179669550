import FilterForm from 'components/forms/FilterForm';
import { CrudFilters, HttpError, useShow } from '@refinedev/core';
import { Divider, Form, Input, Table, TreeSelect, Typography } from 'antd';
import { IAgent } from 'interfaces/agents';
import { IRole } from 'interfaces/roles';
import { Show, TextField, useTable } from '@refinedev/antd';
import { treePermissions } from 'services/permissions';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

interface IFilter {
  name: string;
  login: string;
}

export const RolesShow = () => {
  const { queryResult } = useShow<IRole>();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const { id } = useParams();
  const { tableProps, searchFormProps } = useTable<IAgent, HttpError, IFilter>({
    resource: 'agents',

    onSearch: params => {
      const filters: CrudFilters = [];
      const { name, login } = params;

      filters.push(
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value: name
        },
        {
          field: 'filter[by_login]',
          operator: 'eq',
          value: login
        }
      );

      return filters;
    },

    filters: {
      permanent: [
        {
          field: 'filter[by_role_id]',
          operator: 'eq',
          value: id
        }
      ]
    }
  });

  return (
    <Show isLoading={isLoading} title="Visualizar permissão">
      <Title level={5}>ID</Title>
      <Text>{record?.id}</Text>
      <Title level={5}>Nome</Title>
      <Text>{record?.name}</Text>
      <Title level={5}>Permissões</Title>
      <TreeSelect
        disabled
        treeData={treePermissions()}
        showCheckedStrategy={TreeSelect.SHOW_PARENT}
        treeCheckable={true}
        value={record?.permissions}
      />
      <Divider />
      <Title className="mb-2" level={5}>
        Lista de usuários neste grupo
      </Title>
      <FilterForm searchFormProps={searchFormProps}>
        <Form.Item label="Nome" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Login" name="login">
          <Input />
        </Form.Item>
      </FilterForm>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="login"
          key="login"
          title="Login"
          render={value => <TextField value={value} />}
        />
      </Table>
    </Show>
  );
};
