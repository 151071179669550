import React from 'react';
import { FeaturedIcon, Icon } from '@qcx/ui';
import { Modal, ModalProps, Space, Typography } from 'antd';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';

interface Props extends ModalProps {
  bodyTitle: string;
  bodyText: string;
  onUpdate?: () => void;
}

const ConfirmationModal: React.FC<Props> = ({
  bodyTitle,
  bodyText,
  ...modalProps
}) => {
  return (
    <Modal {...modalProps}>
      <Space direction="vertical">
        <FeaturedIcon
          size="lg"
          icon={<Icon icon={faExclamationTriangle} size="xl" />}
          shape="circleOutlined"
          css={{
            bgColor: '$warning100',
            color: '$warning600',
            borderColor: '$warning50',
            mb: '$4'
          }}
        />
        <Typography.Title level={5} style={{ fontWeight: 500, margin: 0 }}>
          {bodyTitle}
        </Typography.Title>
        <Typography.Text title="fdsfds">{bodyText}</Typography.Text>
      </Space>
    </Modal>
  );
};

export default ConfirmationModal;
