import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'products/possessions',
  label: 'Posses',
  actions: ['list', 'create', 'show', 'update', 'delete'],
  onlyPermission: true,
  parentName: 'ecommerce'
};

export default generateResources(resource);
