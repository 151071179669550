import { AccessControlProvider } from '@refinedev/core';
import { TPermission } from 'interfaces/permissions';

const ALL = '*';

const accessProvider: AccessControlProvider = {
  can: async ({ resource: givenResource, action: givenAction }) => {
    let permissions;
    const storagePermissions = localStorage.getItem('auth-permissions');

    if (storagePermissions) {
      permissions = JSON.parse(storagePermissions);
    }

    if (!permissions) {
      return { can: false };
    }

    const can = permissions.some((permission: TPermission) =>
      [givenResource, ALL].some(resource =>
        [givenAction, ALL].some(
          action => [resource, action].join(':') === permission
        )
      )
    );

    return { can };
  }
};

export default accessProvider;
