import { ClasssroomEnrollmentHeader } from './ClasssroomEnrollmentHeader';
import { Collapse, Empty, Spin } from 'antd';
import { EvaluationsTable } from './EvaluationsTable';
import {
  IClassroomsEnrollmentsData,
  fetchClassroomsEnrollments
} from 'services/graduate-enrollment/evaluations';
import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '@refinedev/core';

interface EvaluationsProps {
  courseEnrollmentId: string;
}

export const TabEvaluations = ({ courseEnrollmentId }: EvaluationsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [classroomsEnrollments, setClassroomsEnrollments] = useState<
    IClassroomsEnrollmentsData[]
  >([]);

  const { open } = useNotification();

  const getClassrooms = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await fetchClassroomsEnrollments(courseEnrollmentId);
      setClassroomsEnrollments(res);
    } catch {
      open?.({
        type: 'error',
        message: 'Erro ao recuperar turmas.',
        description: 'Ops!'
      });
    } finally {
      setIsLoading(false);
    }
  }, [courseEnrollmentId, open]);

  useEffect(() => {
    getClassrooms();
  }, [getClassrooms]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-16">
        <Spin />
      </div>
    );
  }

  if (!isLoading && classroomsEnrollments?.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="Nenhuma turma encontrada"
      />
    );
  }

  return (
    <Collapse>
      {classroomsEnrollments?.map(classroomEnrollment => (
        <Collapse.Panel
          key={classroomEnrollment.id}
          header={
            <ClasssroomEnrollmentHeader
              classroomEnrollment={classroomEnrollment}
            />
          }
        >
          <EvaluationsTable
            key={classroomEnrollment.id}
            openedClassroomId={classroomEnrollment.classroom.id}
            classroomEnrollmentId={classroomEnrollment.id}
          />
        </Collapse.Panel>
      ))}
    </Collapse>
  );
};
