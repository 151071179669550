/* eslint-disable sonarjs/cognitive-complexity */
import 'react-quill/dist/quill.snow.css';
import * as Icons from '@ant-design/icons';
import AssetUpload from 'components/AssetUpload';
import CreateOfferModal from 'components/products/CreateOfferModal';
import ImageSpecifications from 'components/ImageSpecifications';
import ReactQuill from 'react-quill';
import SlugInput from '../../../components/form-items/SlugInput';
import dayjs from 'dayjs';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Tabs,
  Tag
} from 'antd';
import { CanAccess, useModal, useUpdate } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { ICourse } from 'interfaces/courses';
import { IGraduateCourse } from 'interfaces/graduate_courses';
import { IInstructor } from 'interfaces/instructors';
import { IOffer } from 'interfaces/offers';
import { IProduct } from 'interfaces/products';
import { IProductCategory } from 'interfaces/product_categories';
import { applyImageSizeRestriction } from 'utils/apply-image-size-restriction';
import { formatMoney } from 'services/money';
import { getTenant } from '../../../services/tenants';
import { merge } from 'lodash-es';
import { shouldApplyImageRestrictions } from 'utils/should-apply-image-restrictions';
import { useEffect, useState } from 'react';

const { TextArea } = Input;
const tenant = getTenant();
const serviceDatesEnabled = !tenant.disabledFeatures?.includes('serviceDates');

const daysToLabel = (days: number | null) => {
  if (days === null) {
    return 'Não definido';
  }

  return days > 1 ? `${days} dias` : `${days} dia`;
};

export const ProductEdit = () => {
  const { form, formProps, saveButtonProps, queryResult, onFinish } =
    useForm<IProduct>({
      redirect: 'edit',
      warnWhenUnsavedChanges: false
    });
  const [currentResult, setCurrentResult] = useState<IProduct>();

  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: 'courses',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data.productable_id,

    pagination: {
      mode: 'server'
    }
  });

  const { selectProps: graduateCourseSelectProps } = useSelect<IGraduateCourse>(
    {
      resource: 'graduate_courses',
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: queryResult?.data?.data.productable_id,

      pagination: {
        mode: 'server'
      }
    }
  );

  const { selectProps: instructorSelectProps } = useSelect<IInstructor>({
    resource: 'instructors',
    optionLabel: 'name',
    optionValue: 'id',
    defaultValue: queryResult?.data?.data?.info?.relevant_instructor?.id,
    pagination: {
      mode: 'server'
    },
    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ]
  });

  useEffect(() => {
    if (queryResult?.data?.data) {
      setCurrentResult(queryResult?.data?.data);
    }
  }, [queryResult?.data?.data]);

  const { mutateAsync } = useUpdate<IOffer>();

  const disableOffer = (offerId: string) => {
    mutateAsync({
      id: offerId,
      resource: 'offers',
      values: { status: 'disabled' }
    }).then(() => {
      queryResult?.refetch();
    });
  };

  const enableOffer = (offerId: string) => {
    mutateAsync({
      id: offerId,
      resource: 'offers',
      values: { status: 'active' }
    }).then(() => {
      queryResult?.refetch();
    });
  };

  const { selectProps: productCategoriesSelectProps } =
    useSelect<IProductCategory>({
      resource: 'products/categories',
      optionLabel: 'name',
      optionValue: 'id',
      defaultValue: queryResult?.data?.data.categories.map(item => item.id),

      onSearch: value => [
        {
          field: 'filter[by_name]',
          operator: 'eq',
          value
        }
      ],

      pagination: {
        mode: 'server'
      }
    });

  const productType = form.getFieldValue('productable_type');

  const record = queryResult?.data?.data;
  const modal = useModal();
  const [shouldEditSlug, setShouldEditSlug] = useState<boolean>(false);

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar produto">
      <Form
        {...formProps}
        layout="vertical"
        onFinish={values => {
          const result = merge({}, currentResult, values) as IProduct;

          onFinish({ ...values, media: result?.media || {} });
        }}
      >
        <Tabs defaultActiveKey="info">
          <Tabs.TabPane tab="Informação" key="info">
            <CanAccess resource="products" action="slug">
              <Checkbox
                onChange={value => {
                  setShouldEditSlug(value.target.checked);
                }}
                style={{ marginBottom: '16px' }}
              >
                Eu assumo a RESPONSABILIDADE de alterar a SLUG e isso poderá
                quebrar o SEO e a experiência do usuário
              </Checkbox>
            </CanAccess>

            <SlugInput disabled={!shouldEditSlug} />

            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true
                }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Curso"
              name="productable_id"
              rules={[
                {
                  required: true
                }
              ]}
            >
              {productType === 'Course' ? (
                <Select {...courseSelectProps} disabled={true} />
              ) : (
                <Select {...graduateCourseSelectProps} disabled={true} />
              )}
            </Form.Item>

            <Form.Item
              label="Invisível na busca"
              name="hidden"
              valuePropName="checked"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <Switch />
            </Form.Item>

            <Form.Item label="Descrição" name="description">
              <TextArea />
            </Form.Item>

            <Form.Item
              label="Link do descritivo do produto"
              name={['media', 'descriptive_link']}
            >
              <Input />
            </Form.Item>

            <Divider orientation="left" plain>
              Categorias
            </Divider>

            <Form.List name="categories_products">
              {(fields, { add }, { errors }) => (
                <>
                  {fields.map(field => (
                    <div
                      key={field.key}
                      className="flex gap-4 w-full items-center"
                    >
                      <Form.Item name={[field.name, 'id']}>
                        <Input type="hidden" />
                      </Form.Item>
                      <Form.Item
                        className="grow"
                        label="Categoria"
                        name={[field.name, 'category_id']}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <Select {...productCategoriesSelectProps} />
                      </Form.Item>

                      <Form.Item
                        className="grow"
                        label="Tipo"
                        name={[field.name, 'kind']}
                        rules={[
                          {
                            required: true
                          }
                        ]}
                      >
                        <Select>
                          <Select.Option key="primary">Primário</Select.Option>
                          <Select.Option key="secondary">
                            Secundário
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </div>
                  ))}
                  <Form.ErrorList errors={errors} className="mb-4" />
                  <Button
                    type="dashed"
                    className="!flex items-center justify-center"
                    onClick={() => add()}
                    block
                  >
                    <Icons.PlusOutlined />
                    Add Categoria
                  </Button>
                </>
              )}
            </Form.List>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Ofertas" key="offers">
            <div className="ml-auto text-right">
              <Button
                onClick={() => {
                  modal.show();
                }}
                icon={<Icons.PlusCircleFilled />}
              >
                Criar oferta
              </Button>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-10">
              {record?.offers
                ?.sort((a, _) => (a.status === 'active' ? -1 : 1))
                .map(offer => (
                  <div
                    className={`bg-white shadow-md rounded-lg p-6 mb-6 ${
                      offer.status === 'active'
                        ? 'border-2 border-green-500'
                        : ''
                    }`}
                    key={offer.id}
                  >
                    <div className="flex justify-between items-center mb-4">
                      <div>
                        {offer.status === 'active' && (
                          <Badge text="Ativa" color="green" size="small" />
                        )}
                        <h2 className="text-xl font-semibold">{offer.name}</h2>
                        {offer.campaign?.name && (
                          <p className="text-sm text-gray-500">
                            {offer.campaign.name}
                          </p>
                        )}
                      </div>
                      <div>
                        {offer.status === 'active' ||
                        offer.status === 'hidden' ? (
                          <Button
                            className="mr-2"
                            size="small"
                            onClick={() => disableOffer(offer.id)}
                            title="Desabilitar"
                            icon={<Icons.PauseCircleOutlined />}
                          />
                        ) : (
                          <Button
                            className="mr-2"
                            size="small"
                            onClick={() => enableOffer(offer.id)}
                            title="Habilitar"
                            icon={<Icons.PlayCircleOutlined />}
                          />
                        )}
                      </div>
                    </div>
                    <p className="text-lg text-gray-600">
                      De{' '}
                      <span className="line-through">
                        {formatMoney(offer.reference_price)}
                      </span>{' '}
                      por{' '}
                      <span className="text-lg font-semibold">
                        {formatMoney(offer.price)}
                      </span>
                    </p>
                    <Divider />
                    <p>
                      <strong>Período de acesso ao curso:</strong>{' '}
                      {daysToLabel(offer.items[0].time_in_days)}
                    </p>

                    <p>
                      <strong>Configuração de pagamento:</strong>
                      <div className="flex flex-col gap-2 mt-4">
                        <>
                          <Tag className="mr-2">
                            <Icons.DollarOutlined /> Pix:{' '}
                            {displayPaymentConfig(
                              offer.checkout_scheme_config.pix
                            )}
                          </Tag>
                          <Tag className="mr-2">
                            <Icons.BarcodeOutlined /> Boleto:{' '}
                            {displayPaymentConfig(
                              offer.checkout_scheme_config.boleto
                            )}
                          </Tag>
                          <Tag className="mr-2">
                            <Icons.CreditCardOutlined /> com limite:{' '}
                            {displayPaymentConfig(
                              offer.checkout_scheme_config.credit_card
                            )}
                          </Tag>
                          <Tag className="mr-2">
                            <Icons.CreditCardTwoTone /> sem limite:{' '}
                            {offer.checkout_scheme_config.financing_credit_card
                              .max_times
                              ? `${offer.checkout_scheme_config.financing_credit_card.max_times}`
                              : '-'}
                            , Habilitado:{' '}
                            {offer.checkout_scheme_config.financing_credit_card
                              .financing
                              ? '✅'
                              : '❌'}
                          </Tag>
                        </>
                      </div>
                    </p>
                    <p>
                      <strong>Regra de Gateway:</strong>{' '}
                      {offer.gateway_rule?.name}
                    </p>
                    <p>
                      <strong>Centro de Resultado:</strong>{' '}
                      {offer.profit_center?.code}
                    </p>
                    {serviceDatesEnabled && (
                      <>
                        <p>
                          <strong>Início do serviço:</strong>{' '}
                          {offer.service_start_at
                            ? new Date(
                                offer.service_start_at
                              ).toLocaleDateString()
                            : 'Na compra'}
                        </p>
                        <p>
                          <strong>Duração do serviço:</strong>{' '}
                          {offer.service_duration_in_months
                            ? `${offer.service_duration_in_months} ${
                                offer.service_duration_in_months > 1
                                  ? 'meses'
                                  : 'mês'
                              }`
                            : ''}
                        </p>
                      </>
                    )}
                    <p>
                      <strong>Status:</strong>{' '}
                      {offer.status === 'disabled'
                        ? 'Desabilitado'
                        : offer.status === 'expired'
                        ? 'Expirado'
                        : offer.status === 'active'
                        ? 'Ativo'
                        : offer.status === 'hidden'
                        ? 'Escondido e ativo'
                        : '-'}
                    </p>
                    <p>
                      <strong>Tipo:</strong>{' '}
                      {offer.kind === 'online'
                        ? 'Vitrine'
                        : offer.kind === 'management'
                        ? 'Manual'
                        : '-'}
                    </p>
                    <p className="text-sm text-gray-500 mt-4">
                      <strong>ID:</strong> {offer.id}
                    </p>
                  </div>
                ))}
            </div>
            <br></br>
            <CreateOfferModal
              modal={modal}
              product={record}
              onUpdate={() => {
                queryResult?.refetch();
              }}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab="Conteúdo" key="content">
            <Form.Item name={['info', 'about']} label="Sobre">
              <ReactQuill theme="snow" />
            </Form.Item>
            <Form.Item name={['info', 'trailer']} label="Trailer do produto">
              <Input placeholder="Ex: https://www.youtube.com/watch?v=OWQiVuNJNfY" />
            </Form.Item>
            <Divider orientation="left" plain>
              Objetivos
            </Divider>
            <Form.List name={['info', 'goals']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <div key={field.key} className="flex gap-5 w-full">
                      <Form.Item name={[field.name]}>
                        <Input.TextArea style={{ width: 500 }} />
                      </Form.Item>
                      <Icons.MinusCircleOutlined
                        className="mb-4"
                        onClick={() => remove(field.name)}
                      />
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    className="!flex items-center justify-center"
                    onClick={() => add()}
                    block
                  >
                    <Icons.PlusOutlined />
                    Add Objetivo
                  </Button>
                </>
              )}
            </Form.List>
            <Divider orientation="left" plain>
              Detalhes
            </Divider>
            <Form.List name={['info', 'details']}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(field => (
                    <div key={field.key} className="flex gap-5 w-full">
                      <Form.Item name={[field.name, 'title']}>
                        <Input />
                      </Form.Item>
                      <Form.Item
                        className="grow"
                        name={[field.name, 'content']}
                      >
                        <Input.TextArea />
                      </Form.Item>
                      <Icons.MinusCircleOutlined
                        className="mb-4"
                        onClick={() => remove(field.name)}
                      />
                    </div>
                  ))}
                  <Button
                    type="dashed"
                    className="!flex items-center justify-center"
                    onClick={() => add()}
                    block
                  >
                    <Icons.PlusOutlined />
                    Add Detalhe
                  </Button>
                </>
              )}
            </Form.List>
            <Divider orientation="left" plain>
              Professor em destaque
            </Divider>
            <Form.Item
              label="Professor relacionado"
              name={['info', 'relevant_instructor', 'id']}
            >
              <Select
                {...instructorSelectProps}
                options={[{ label: 'Nenhum', value: '' }].concat(
                  instructorSelectProps?.options || []
                )}
              />
            </Form.Item>
            <Form.Item
              name={['info', 'relevant_instructor', 'name']}
              label="Nome do professor"
            >
              <Input placeholder="Ex: Arthur Rollo" />
            </Form.Item>
            <Form.Item
              name={['info', 'relevant_instructor', 'title']}
              label="Cargo de exibição"
            >
              <Input placeholder="Ex: Coordenador do curso" />
            </Form.Item>
            <Form.Item
              name={['info', 'relevant_instructor', 'description']}
              label="Currículo de exibição"
            >
              <Input placeholder="Ex: Procurador da companhia metropolitaba de São Paulo (Metro)" />
            </Form.Item>

            <ImageSpecifications
              title="Especificações para Card de exibição"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'Card de exibição' : ''}
              name={['info', 'relevant_instructor', 'image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.info?.relevant_instructor?.image}
                maxSize={applyImageSizeRestriction('cardExibicao')}
              />
            </Form.Item>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Assets" key="assets">
            <ImageSpecifications
              title="Especificações Card"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'Card' : ''}
              name={['media', 'card_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.media?.card_image}
                maxSize={applyImageSizeRestriction('card')}
              />
            </Form.Item>

            <ImageSpecifications
              title="Especificações Card de conteúdo gratuito"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={
                !shouldApplyImageRestrictions()
                  ? 'Card de conteúdo gratuito'
                  : ''
              }
              name={['media', 'card_free_content_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.media?.card_free_content_image}
                maxSize={applyImageSizeRestriction('cardConteudoGratuito')}
              />
            </Form.Item>

            <ImageSpecifications
              title="Especificações Capa"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'Capa' : ''}
              name={['media', 'hero_section_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.media?.hero_section_image}
                maxSize={applyImageSizeRestriction('capa')}
              />
            </Form.Item>

            <ImageSpecifications
              title="Especificações Saiba mais"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'Saiba mais' : ''}
              name={['media', 'more_section_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.media?.more_section_image}
                maxSize={applyImageSizeRestriction('saibaMais')}
              />
            </Form.Item>

            <ImageSpecifications
              title="Especificações O que esperar"
              size="500kb"
              format="jpeg/jpg/png"
            />
            <Form.Item
              label={!shouldApplyImageRestrictions() ? 'O que esperar' : ''}
              name={['media', 'about_section_image']}
              rules={[
                {
                  required: false
                }
              ]}
            >
              <AssetUpload
                accept="image/png, image/jpeg"
                type="product"
                src={queryResult?.data?.data?.media?.about_section_image}
                maxSize={applyImageSizeRestriction('oQueEsperar')}
              />
            </Form.Item>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Custom" key="custom">
            <Divider orientation="left" plain>
              Dados usados para ordenação
            </Divider>
            <Form.Item label="Número de buscas" name="search_count">
              <Input type="number" />
            </Form.Item>
            <Form.Item label="Número de compras" name="purchases_count">
              <Input type="number" />
            </Form.Item>
            <Form.Item label="Média de feedback" name="feedback_average">
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Data de lançamento"
              name="launched_at"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              getValueProps={(value: any) => ({
                value: value ? dayjs(value) : ''
              })}
            >
              <DatePicker />
            </Form.Item>
            <Form.Item
              label="Top Score"
              name="top_score"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              label="Highlight Score"
              name="highlight_score"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Edit>
  );
};

const displayPaymentConfig = (config: Record<string, unknown>) => {
  return config.max_times
    ? `${config.max_times}x, Financiamento: ${config.financing ? '✅' : '❌'}`
    : 'Desabilitado';
};
