import ClassroomInfo from './components/ClassroomInfo';
import ClassroomProfessor from './components/ClassroomProfessor';
import ClassroomSupplementarMaterial from './components/ClassroomSupplementarMaterial';
import { ClassroomContent } from './components/ClassroomContent';
import { ClassroomEnrollments } from './components/ClassroomEnrollments';
import { ClassroomEvaluations } from './components/ClassroomEvaluations';
import { ClassroomStudentsAvailableForEnrollment } from './components/ClassroomStudentsAvailableForEnrollment';
import { Edit, useForm } from '@refinedev/antd';
import { Form, Tabs } from 'antd';
import { IClassroom } from 'interfaces/classrooms';
import { ITree } from 'interfaces/tree';
import { toSaoPauloTimeZone } from '../../services/date';
import { useState } from 'react';

const convertToTimeZoneRange = (startDate?: string, endDate?: string) => {
  return [
    startDate ? toSaoPauloTimeZone(startDate) : null,
    endDate ? toSaoPauloTimeZone(endDate) : null
  ];
};

export const ClassroomEdit = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [contentTree, setContentTree] = useState<ITree>();
  const { formProps, saveButtonProps, queryResult, onFinish } =
    useForm<IClassroom>({
      redirect: 'edit'
    });

  const classroom = queryResult?.data?.data;

  const getInitialValues = () => {
    const initialValues = { ...formProps.initialValues };

    initialValues.dateRangeValidity = convertToTimeZoneRange(
      initialValues?.starts_at,
      initialValues?.ends_at
    );

    initialValues.dateRangeContent = convertToTimeZoneRange(
      initialValues?.content_access_starts_at,
      initialValues?.content_access_ends_at
    );

    initialValues.dateRangeDiscussion = convertToTimeZoneRange(
      initialValues?.discussion_starts_at,
      initialValues?.discussion_ends_at
    );

    return initialValues;
  };

  const handleOnFinish = (values: Record<string, unknown>) => {
    const dateRangeValidity = values.dateRangeValidity as string[];
    const dateRangeDiscussion = values.dateRangeDiscussion as string[];
    const dateRangeContent = values.dateRangeContent as string[];
    const parsedValues = {
      ...values,
      content_access_starts_at: toSaoPauloTimeZone(dateRangeContent[0]),
      content_access_ends_at: toSaoPauloTimeZone(dateRangeContent[1]),
      discussion_starts_at: toSaoPauloTimeZone(dateRangeDiscussion[0]),
      discussion_ends_at: toSaoPauloTimeZone(dateRangeDiscussion[1]),
      starts_at: toSaoPauloTimeZone(dateRangeValidity[0]),
      ends_at: toSaoPauloTimeZone(dateRangeValidity[1]),
      content_tree: contentTree,
      minimum_attendance: values.minimum_attendance ?? null
    };

    onFinish(parsedValues);
  };

  return (
    <Edit
      title="Editar turma"
      footerButtonProps={{
        style: {
          marginRight: 8,
          justifyContent: 'flex-end',
          display: ['info', 'content'].includes(activeTab) ? 'flex' : 'none'
        }
      }}
      saveButtonProps={saveButtonProps}
    >
      <Tabs
        defaultActiveKey={activeTab}
        onChange={activeKey => setActiveTab(activeKey)}
      >
        <Tabs.TabPane tab="Informações gerais" key="info">
          {classroom && (
            <Form
              {...formProps}
              initialValues={getInitialValues()}
              onFinish={handleOnFinish}
              layout="vertical"
            >
              <ClassroomInfo classroom={classroom} formProps={formProps} />
            </Form>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Conteúdo" key="content">
          {classroom && (
            <Form
              {...formProps}
              initialValues={getInitialValues()}
              onFinish={handleOnFinish}
              layout="vertical"
            >
              <ClassroomContent
                classroom={classroom}
                onChange={data => {
                  setContentTree(data);
                  queryResult.refetch();
                }}
              />
            </Form>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Avaliações" key="evaluations">
          {classroom && (
            <ClassroomEvaluations
              disciplineId={classroom.discipline.id}
              classroomId={classroom.id}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Alunos matriculados" key="enrollments">
          {classroom && (
            <ClassroomEnrollments
              classroomId={classroom.id}
              classroom={classroom}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Alunos disponíveis para matrícula" key="available">
          {classroom && (
            <ClassroomStudentsAvailableForEnrollment
              classroomId={classroom.id}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Professor responsável" key="professor">
          {classroom && <ClassroomProfessor classroom={classroom} />}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Material complementar" key="supplementar_material">
          {classroom && <ClassroomSupplementarMaterial classroom={classroom} />}
        </Tabs.TabPane>
      </Tabs>
    </Edit>
  );
};
