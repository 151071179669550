import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  TextField,
  useTable
} from '@refinedev/antd';
import { IRole } from 'interfaces/roles';
import { Space, Table } from 'antd';

export const RoleList = () => {
  const { tableProps } = useTable<IRole>();

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={value => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title="Nome"
          render={value => <TextField value={value} />}
        />
        <Table.Column<IRole>
          title="Ações"
          dataIndex="actions"
          align="center"
          render={(_, record) => {
            return (
              <Space>
                <ShowButton hideText size="small" recordItemId={record.id} />
                <EditButton hideText size="small" recordItemId={record.id} />
                <DeleteButton hideText size="small" recordItemId={record.id} />
              </Space>
            );
          }}
        />
      </Table>
    </List>
  );
};
