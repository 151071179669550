import { RoleCreate, RoleEdit, RoleList, RolesShow } from 'pages/admin/roles';
import { generateResources } from 'utils/GenerateResouces';

const resource = {
  name: 'roles',
  label: 'Permissões e papéis',
  list: RoleList,
  edit: RoleEdit,
  create: RoleCreate,
  show: RolesShow,
  actions: ['list', 'show', 'edit', 'create', 'delete'],
  parentName: 'configurations'
};

export default generateResources(resource);
