import { BoletoIcon } from '../../assets/icons/payment-methods/boleto';
import { CreditCardIcon } from '../../assets/icons/payment-methods/credit-card';
import {
  IInstallmentConfig,
  IPaymentMethodRules,
  PaymentMethodsType
} from '../../interfaces/payment_methods-rules';
import { PixIcon } from '../../assets/icons/payment-methods/pix';
import { ReactNode } from 'react';
import { RecurrenceIcon } from '../../assets/icons/payment-methods/recurrence';
import { Row } from 'antd';

export const MAX_CC_INSTALLMENTS = 12;
export const INSTALLMENTS_MAX = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24
];

export type IPaymentMethodSwitchState = Record<
  PaymentMethodsType,
  { active: boolean; label: string }
>;

export const addDefaults = (
  methodConfig: IInstallmentConfig[] = [],
  mode: 'edit' | 'create' = 'create',
  max = 24
) =>
  (max < 24 ? INSTALLMENTS_MAX.slice(0, max) : INSTALLMENTS_MAX).map(
    installment => {
      const existing = methodConfig.find(
        item => item.installment === installment
      );
      return existing
        ? {
            ...existing,
            active: true,
            price_percentage: existing.price_percentage * 100
          }
        : {
            installment,
            price_percentage: 100,
            active: mode === 'create'
          };
    }
  );

export const defaultEditConfig = (config: IPaymentMethodRules['config']) => {
  return {
    pix: addDefaults(config?.pix, 'edit'),
    credit_card: addDefaults(config?.credit_card, 'edit', MAX_CC_INSTALLMENTS),
    financing_credit_card: addDefaults(config?.financing_credit_card, 'edit'),
    boleto: addDefaults(config?.boleto, 'edit')
  };
};

export const defaultCreateConfig = () => {
  return {
    pix: addDefaults(),
    credit_card: addDefaults([], 'create', MAX_CC_INSTALLMENTS),
    financing_credit_card: addDefaults(),
    boleto: addDefaults()
  };
};

export type IPaymentMethodsControl = Record<
  PaymentMethodsType,
  { active: boolean; label: string }
>;

export const renderSummary = (
  methods: IPaymentMethodsControl,
  config: IPaymentMethodRules['config']
) => {
  return Object.keys(methods).map((methodKey: string) => {
    const method = methods[methodKey as PaymentMethodsType];

    if (method.active) {
      const installments = config[methodKey as PaymentMethodsType]
        ?.filter(item => item.active)
        .map(item => item.installment);

      if (installments && installments.length > 0) {
        // const selectedInstallments = installments.join(', ');
        const selectedInstallments = `${installments.length} Parcela(s)`;
        return (
          <Row
            key={methodKey}
            align="middle"
            justify="space-between"
            style={{ fontSize: 12 }}
          >
            <div className="font-medium">{method.label}</div>
            <div>{selectedInstallments}</div>
          </Row>
        );
      }
    }
    return null;
  });
};

export const getActiveMethods = (methods: IPaymentMethodsControl) => {
  return Object.keys(methods).filter(
    key => methods[key as PaymentMethodsType].active
  ) as PaymentMethodsType[];
};

export const filterActiveConfig = (
  activeMethods: PaymentMethodsType[],
  config: IPaymentMethodRules['config']
) =>
  activeMethods.reduce((acc, method) => {
    if (config[method]) {
      acc[method] = config[method]
        .filter(installment => installment.active)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .map(({ active, price_percentage, ...rest }) => ({
          ...rest,
          price_percentage: price_percentage / 100
        }));
    }
    return acc;
  }, {} as IPaymentMethodRules['config']);

export const defaultSwitchStateOnCreate = {
  credit_card: {
    active: false,
    label: 'Cartão de Crédito'
  },
  financing_credit_card: {
    active: false,
    label: 'Recorrência'
  },
  pix: { active: false, label: 'Pix' },
  boleto: { active: false, label: 'Boleto' }
} as IPaymentMethodSwitchState;

export const defaultSwitchStateOnEdit = (
  config: IPaymentMethodRules['config']
) =>
  Object.keys(defaultSwitchStateOnCreate).reduce((acc, method) => {
    acc[method as PaymentMethodsType] = {
      ...defaultSwitchStateOnCreate[method as PaymentMethodsType],
      active: config[method as PaymentMethodsType]?.length > 0
    };
    return acc;
  }, {} as IPaymentMethodSwitchState);

export const paymentMethodsIcons = {
  credit_card: <CreditCardIcon />,
  financing_credit_card: <RecurrenceIcon />,
  pix: <PixIcon />,
  boleto: <BoletoIcon />
} as Record<PaymentMethodsType, ReactNode>;
