import * as Icons from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Typography
} from 'antd';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { ICoupon } from 'interfaces/coupons';
import { IProduct } from 'interfaces/products';
import { STATUSES, translateStatus } from 'services/coupons';
import { useCreate, useNavigation } from '@refinedev/core';
import { useEffect, useState } from 'react';

export const CouponEdit = () => {
  const { form, formProps, saveButtonProps, queryResult } = useForm<ICoupon>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [discountItems, setDiscountItems] = useState<Record<string, any>[]>([]);
  const [ruleItems, setRuleItems] = useState<Record<string, unknown>[]>([]);

  const onFormChange = () => {
    setRuleItems(form.getFieldValue(['rule', 'propositions']));
    setDiscountItems(form.getFieldValue('discounts'));
  };

  const { listUrl, replace } = useNavigation();

  const { mutate, isLoading } = useCreate<ICoupon>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const editCoupon = (values: any) => {
    return new Promise<ICoupon>((resolve, reject) => {
      mutate(
        {
          resource: `coupons`,
          values,
          successNotification: () => ({
            type: 'success',
            description: 'Sucesso!',
            message: 'Cupom editado com sucesso'
          })
        },
        {
          onSuccess: () => {
            replace(listUrl('coupons'));
          },
          onError: error => reject(error)
        }
      );
    });
  };

  const { selectProps: productSelectProps } = useSelect<IProduct>({
    resource: 'products',
    optionLabel: 'name',
    optionValue: 'id',

    onSearch: value => [
      {
        field: 'filter[name]',
        operator: 'eq',
        value
      }
    ],

    pagination: {
      mode: 'server'
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCreate = async (values: any) => {
    await editCoupon(values);
  };

  useEffect(() => {
    onFormChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResult]);

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      footerButtonProps={{ style: { display: 'none' } }}
      title="Editar cupom"
    >
      <Form
        {...formProps}
        layout="vertical"
        onValuesChange={onFormChange}
        onFinish={onCreate}
      >
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item
          label="Código"
          name="code"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Descrição"
          name="description"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Aplicar Automaticamente?"
          name="is_auto_applied"
          valuePropName="checked"
        >
          <Switch disabled />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true
            }
          ]}
        >
          <Select>
            {STATUSES.map(status => (
              <Select.Option key={status}>
                {translateStatus(status)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Expiração"
          name="expires_at"
          getValueProps={value => {
            return {
              value: value ? dayjs(value) : ''
            };
          }}
          rules={[
            {
              required: true
            }
          ]}
        >
          <DatePicker />
        </Form.Item>
        <Typography.Title level={5}>Regras</Typography.Title>
        <Form.List name={['rule', 'propositions']}>
          {(fields, _, { errors }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-4 w-full items-center">
                  <Form.Item
                    label="Regra"
                    name={[field.name, 'key']}
                    rules={[{ required: true }]}
                  >
                    <Select className="mb-4 w-full">
                      <Select.Option key="min_offers_count">
                        Mínimo de ofertas no carrinho
                      </Select.Option>
                      <Select.Option key="max_offers_count">
                        Máximo de ofertas no carrinho
                      </Select.Option>
                      <Select.Option key="max_coupon_usage_by_user_count">
                        Número máximo de uso por usuário
                      </Select.Option>
                      <Select.Option key="max_total_usage_count">
                        Número total de usos
                      </Select.Option>
                      <Select.Option key="selected_products">
                        Seleção de produtos
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {ruleItems[field.name]?.key === 'selected_products' && (
                    <Form.Item
                      label="Produtos"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <Select
                        {...productSelectProps}
                        mode="multiple"
                        style={{ width: 500 }}
                      />
                    </Form.Item>
                  )}

                  {ruleItems[field.name]?.key !== 'selected_products' && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber />
                    </Form.Item>
                  )}
                </div>
              ))}
              <Form.ErrorList errors={errors} className="mb-4" />
            </>
          )}
        </Form.List>
        <Typography.Title level={5}>Descontos</Typography.Title>
        <Form.List name="discounts">
          {(fields, _, { errors }) => (
            <>
              {fields.map(field => (
                <div key={field.key} className="flex gap-4 w-full items-center">
                  <Form.Item
                    label="Tipo"
                    name={[field.name, 'kind']}
                    rules={[{ required: true }]}
                  >
                    <Select
                      disabled
                      onChange={value => {
                        form.setFields([
                          { name: [field.name, 'kind'], value: value }
                        ]);
                      }}
                    >
                      <Select.Option key="percentage">
                        Porcentagem
                      </Select.Option>
                      <Select.Option key="amount">Valor</Select.Option>
                      <Select.Option key="percentage_on_most_expensive_item">
                        Porcentagem no item mais caro
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {['percentage_on_most_expensive_item', 'percentage'].includes(
                    discountItems[field.name]?.kind
                  ) && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                    >
                      <InputNumber disabled addonAfter="%" precision={2} />
                    </Form.Item>
                  )}

                  {discountItems[field.name]?.kind === 'amount' && (
                    <Form.Item
                      label="Valor"
                      name={[field.name, 'value']}
                      rules={[{ required: true }]}
                      getValueProps={value => {
                        return {
                          value: value / 100
                        };
                      }}
                    >
                      <InputNumber disabled addonBefore="R$" precision={2} />
                    </Form.Item>
                  )}
                </div>
              ))}
              <Form.ErrorList errors={errors} className="mb-4" />
            </>
          )}
        </Form.List>
        <Button
          icon={<Icons.SaveFilled />}
          htmlType="submit"
          type="primary"
          loading={isLoading}
        >
          Salvar
        </Button>
      </Form>
    </Edit>
  );
};
