import { ContentCreateDropdown } from './components/ContentCreateDropdown';
import { ContentsTable } from './components/ContentsTable';
import { CreateButton, Show, useTable } from '@refinedev/antd';
import { HttpError, useOne } from '@refinedev/core';
import { IGroup } from 'interfaces/epimetheus/groups';
import { ILibrary, ILibraryContent } from 'interfaces/libraries';
import { Tabs, Typography } from 'antd';
import { TagGroupsTable } from './components/TagGroupsTable';
import { useParams } from 'react-router-dom';

export const LibrariesShow = () => {
  const { id } = useParams();

  const { data } = useOne<ILibrary>({
    resource: 'prometheus/v2/libraries',
    id
  });

  const { tableProps: tagGroupsTableProps } = useTable<IGroup, HttpError>({
    resource: `prometheus/v2/libraries/${id}/tag_groups`,
    meta: {
      libraryId: id
    }
  });

  const { tableProps, tableQueryResult } = useTable<
    ILibraryContent,
    HttpError,
    { byName: string }
  >({
    resource: `prometheus/v2/libraries/${id}/contents`,
    onSearch: ({ byName }: { byName: string }) => [
      {
        field: 'filter[q]',
        operator: 'eq',
        value: byName
      }
    ]
  });

  const onFinish = () => {
    tableQueryResult.refetch();
  };

  return (
    <Show title="Ver biblioteca">
      <Typography.Title level={3}>{data?.data?.name}</Typography.Title>

      <Tabs defaultActiveKey={'contents'}>
        <Tabs.TabPane tab="Conteúdos" key="contents">
          <ContentCreateDropdown onFinish={onFinish} libraryId={id!} />
          <ContentsTable tableProps={tableProps} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Grupos de tags" key="tag_groups">
          <CreateButton
            resource="tag-groups"
            meta={{ libraryId: id }}
            style={{
              marginLeft: 'auto',
              display: 'block',
              marginTop: 16,
              marginBottom: 16
            }}
          >
            Novo grupo de tags
          </CreateButton>
          <TagGroupsTable tableProps={tagGroupsTableProps} />
        </Tabs.TabPane>
      </Tabs>
    </Show>
  );
};
