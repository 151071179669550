import { Edit, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';
import { IInteractiveClass } from 'interfaces/interactive_classes';

export const InteractiveClassEdit = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IInteractiveClass>({
    redirect: 'edit'
  });

  const result = queryResult?.data?.data;

  return (
    <Edit saveButtonProps={saveButtonProps} title="Editar aula">
      <Form {...formProps} layout="vertical">
        <Form.Item
          name="title"
          label="Título da aula"
          rules={[{ required: true, message: 'O título da aula é obrigatório' }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Descrição">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Edit >
  )
}
