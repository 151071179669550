import {
  IResource,
  IResourceAndRoutes,
  TResourceAction
} from 'interfaces/resources';
import { RESOURCES } from 'resources';
import { TPermission } from 'interfaces/permissions';
import { me } from 'requests/agents';

const ROLES_KEY = 'auth-permissions';
const ALL = '*';

const format = (resource: string, action: TResourceAction) =>
  [resource, action].join(':');

export const treePermissions = () => {
  const tree = RESOURCES.map((resourceAndRoutes: IResourceAndRoutes) => {
    const resource = resourceAndRoutes.resource as IResource;
    const { name, actions, label } = resource;

    return {
      title: label,
      value: format(name, ALL),
      key: format(name, ALL),
      children: actions?.map((action: string) => ({
        title: format(name, action),
        value: format(name, action),
        key: format(name, action)
      }))
    };
  });

  return [
    {
      title: 'super',
      value: format(ALL, ALL),
      key: format(ALL, ALL),
      children: tree
    }
  ];
};

export const getPermissions = async (): Promise<TPermission[]> => {
  const persistedPermissions = get();

  if (persistedPermissions) {
    return JSON.parse(persistedPermissions);
  }

  const permissions = await load();

  set(permissions);

  return permissions;
};

export const clearPermissions = () => {
  localStorage.removeItem(ROLES_KEY);
};

export const refreshPermissions = async () => {
  const permissions = await load();

  set(permissions);
};

const get = () => localStorage.getItem(ROLES_KEY);

const set = (permissions: TPermission[]) => {
  localStorage.setItem(ROLES_KEY, JSON.stringify(permissions));
};

const load = async (): Promise<TPermission[]> => {
  const { permissions, role_permissions } = await me();

  return [...permissions, ...role_permissions];
};
