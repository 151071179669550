import type { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  token: {
    colorTextHeading: '#344054',
    colorText: '#1D2939',
    colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
    colorPrimary: '#7F56D9',
    colorSuccess: '#52c41a',
    colorError: '#D92D20',
    colorWarning: '#faad14',
    colorLink: '#1890ff',
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorBorder: '#d9d9d9',
    colorTextLabel: '#1D2939',
    colorPrimaryBg: '#7F56D9',
    borderRadius: 6,
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    fontFamily:
      'Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
  },
  components: {
    Layout: {
      siderBg: '#342C49',
      triggerBg: 'rgba(0, 0, 0, 0.5)',
      colorBgContainer: '#ffffff'
    },
    Menu: {
      popupBg: '#342C49',
      colorHighlight: '#ffffff',
      itemColor: '#ffffff',
      itemSelectedColor: '#ffffff',
      itemSelectedBg: 'transparent',
      subMenuItemBg: 'trasparent',
      colorBgTextActive: 'transparent',
      itemHoverBg: 'transparent',
      itemHoverColor: '#ffffff',
      itemActiveBg: 'transparent'
    },
    Switch: {
      colorPrimary: '#7F56D9',
      colorPrimaryBg: '#7F56D9'
    }
  }
};
