import * as Icons from '@ant-design/icons';
import { Button, Tooltip } from 'antd';

interface CopyToClipboardProps {
  text: string;
  tooltipText?: string;
  children?: React.ReactNode;
}

export const CopyToClipboard = ({
  text,
  children,
  tooltipText = 'Copiar'
}: CopyToClipboardProps) => {
  const clipboardButton = (
    <Tooltip title={tooltipText}>
      <Button
        size="small"
        icon={<Icons.CopyTwoTone />}
        onClick={() => navigator.clipboard.writeText(text)}
      />
    </Tooltip>
  );

  return children ? (
    <div className="flex">
      {children} {clipboardButton}
    </div>
  ) : (
    clipboardButton
  );
};
